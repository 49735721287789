import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import { ClipLoader } from 'react-spinners';
import GomusLogo from '../../../assets/img/logo.png';
import HeringLogo from '../../../assets/img/logo-hering.png';
import { signInRequest } from 'store/modules/auth/actions';
import { initialValues, schema } from './formConfig';
import OAuth2Login from 'react-simple-oauth2-login';
import { FiArrowLeft, FiArrowUpLeft, FiSkipBack } from 'react-icons/fi';
import { FaFacebook, FaSpotify } from 'react-icons/fa';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import Select from 'react-select';
import * as yup from 'yup';
import moment from "moment";
import api from 'services/api';
import { toast } from 'react-toastify';

registerLocale("pt", pt);

export default function Login() {
  const company = process.env.REACT_APP_COMPANY;

  const dispatch = useDispatch();
  const { isLoading, responseError } = useSelector((state) => state.auth);
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const [mode, setMode] = useState('Login');

  const logo = (company === 'hering' ? HeringLogo : GomusLogo);

  const [isLoadingPage, setIsLodingPage] = useState(false);
  const [hasNameError, setHasNameError]  = useState(true);

  return (
    <>
      <img src={logo} style={{padding: '20px', margin: 'auto', marginBottom: '30px', width: '400px'}} />
      <div className="text-center">
        <div className="w-6/12 p-4 inline-block">
          {isLoadingPage ? <>
            <ClipLoader size={12} color="white" />
            Carregando...
          </> : <>
            <Formik
              enableReinitialize
              initialValues={{
                name: '',
              }}
              validationSchema={yup.object().shape({
                name: yup.string().required('O campo é obrigatório'),
              })}
              onSubmit={(values, { setSubmitting }) => {
                if (!values.name || values.name.length <= 3) {
                  setSubmitting(false);
                  setHasNameError(true);
                  return;
                }

                var aux = { ...values };

                let password = moment(new Date()).format("YYYYMMDDhh:mm:ss") + '+' + parseInt(Math.random() * 10000000);

                aux = {
                  ...aux,
                  birthdate: moment(new Date()).format("YYYY-MM-DD"),
                  gender: 'PREFIRO_NAO_INFORMAR',
                  email:  moment(new Date()).format("YYYYMMDDhh:mm:ss")+ '+' + parseInt(Math.random() * 10000000) + '@heringjukebox.gomus.com.br',
                  password: password,
                  confirmPassword: password,
                };

                api
                  .post('api/client/register', aux)
                  .then((e) => {
                    toast.success(
                      `Cadastro efetuado com sucesso!`
                    );
                    dispatch(signInRequest({ username: aux.email, password: aux.password }));

                    setSubmitting(false);
                  })
                  .catch((item) => {
                  })

              }}
            >
              {({
                handleChange,
                handleBlur,
                setFieldValue,
                handleSubmit,
                values,
              }) => (
                <form onSubmit={handleSubmit} className="w-full inline-block text-left">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-600 text-xs font-bold mb-2"
                      htmlFor="name"
                    >
                      Nome
                      <input
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        name="name"
                        className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Digite seu nome"
                      />
                    </label>
                    <ErrorMessage
                      component="p"
                      className="text-red mb-4 font-light text-xs"
                      name="name"
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                      disabled={isLoading}
                      className="bg-black text-white text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      {isLoading ? (
                        <ClipLoader size={12} color="white" />
                      ) : (
                        "Cadastre-se"
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </>}
        </div>
      </div>
    </>
  );
}
