import React, { Profiler, useState, useEffect } from 'react';
import { FiDownload, FiPlus, FiRefreshCcw, FiRefreshCw, FiTrash } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';

import useDelete from 'services/hooks/useDelete';
import { useSelector } from 'react-redux';
import useGetList from 'services/hooks/useGetList';
import SearchEngine from 'components/Search/SearchEngine';
import TablePaginator from 'components/Paginator';
import { Modal, ModalButton } from 'components/Modals/Modal';
import PageCard from 'components/Cards/PageCard';
import Sorting from "components/Sorting";
import ModalDetails from './ModalDetails';
import ModalForm from './ModalForm';
import api from 'services/api';
import { useProfile } from 'services/profile/getProfile';
import { Container } from '../../../assets/shared/styles';
import CardTableList from 'components/Cards/CardTableList';

const Company = ({ color = 'light' }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const search = useSelector((state) => state.filter.value);
  const [openForm, setOpenForm] = useState(false);
  const [selected, setSelected] = useState({});
  const { user } = useProfile();
  const [action, setAction] = useState(1);
  const [sortingString, setSortingString] = useState("");
  const [direction, setDirection] = useState("");
  const [currentSortingField, setCurrentSortingField] = useState("");
  const [currentSortingDirection, setCurrentSortingDirection] = useState(false);

  // get LIST
  const {
    data: companies,
    getItems,
    isLoading: isLoadingList,
    meta,
  } = useGetList({
    page: currentPage,
    route: `adm/company/list?&${sortingString}`,
    search,
    sortingString,
  });

  // DELETE
  const { apiDelete, isLoading: isLoadingDelete } = useDelete({
    errorMessage: 'Falha ao apagar empresa, tente novamente mais tarde',
    successMessage: 'Empresa removido com sucesso',
    onSuccess: getItems,
  });

  function handleEdit(identifier) {
    setOpenForm(true);
    setSelected({ identifier });
  }

  const isLoading = isLoadingList || isLoadingDelete;

  useEffect(() => {
    getItems();
}, [action, sortingString]);

  return (
    <>
      <PageCard
        color={color}
        headerContent={
          <>
            <SearchEngine role={user.role} fields={['search', 'title']} />
          </>
        }
      >
        <Modal
          title={`${selected.identifier ? 'Editar' : 'Nova'} Empresa`}
          show={openForm}
          setShow={setOpenForm}
        >
          <ModalForm
            onSuccess={() => getItems()}
            identifier={selected.identifier}
          />
        </Modal>
        <CardTableList title="Marcas" color={color}>
          <div className="relative mb-2">
            <div className="absolute bottom-0 right-0">
              <button
                type="button"
                title="Atualizar listagem"
                className="p-2 mr-3 auto rounded bg-black"
                onClick={getItems}
              >
                <FiRefreshCw size={15} color="#fff" />
              </button>
              <button
                type="button"
                title="Adicionar nova empresa"
                className="p-2 mr-3 auto rounded bg-black"
                onClick={() => {
                  setSelected({});
                  setOpenForm(true);
                }}
              >
                <FiPlus size={15} color="#fff" />
              </button>
            </div>
          </div>
          <Container>
            <div className="overflow-auto">
              <table className="items-center table-auto w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th
                      className={`align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      <Sorting
                        label="Nome"
                        field="e.name"
                        currentSortingField={currentSortingField}
                        setCurrentSortingField={setCurrentSortingField}
                        currentSortingDirection={currentSortingDirection}
                        setCurrentSortingDirection={setCurrentSortingDirection}
                        setSortingString={setSortingString}
                      />
                    </th>
                    <th
                      className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      <Sorting
                        label="Descrição"
                        field="e.description"
                        currentSortingField={currentSortingField}
                        setCurrentSortingField={setCurrentSortingField}
                        currentSortingDirection={currentSortingDirection}
                        setCurrentSortingDirection={setCurrentSortingDirection}
                        setSortingString={setSortingString}
                      />
                    </th>
                    <th
                      className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      <Sorting
                        label="Data de criação"
                        field="e.createdAt"
                        currentSortingField={currentSortingField}
                        setCurrentSortingField={setCurrentSortingField}
                        currentSortingDirection={currentSortingDirection}
                        setCurrentSortingDirection={setCurrentSortingDirection}
                        setSortingString={setSortingString}
                      />
                    </th>
                    <th
                      className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companies?.map((company) => {
                    const { identifier, name, description, createdAtFormatted } = company;

                    return (
                      <>
                        <tr key={identifier}>
                          <td className="text-xs whitespace-nowrap">
                            <span
                              className={`font-bold ${+(color === 'light'
                                ? 'text-blueGray-600'
                                : 'text-white')}`}
                            >
                              {name}
                            </span>
                          </td>
                          <td className="text-xs font-light whitespace-nowrap">
                            <span
                              className={`${+(color === 'light'
                                ? 'text-blueGray-600'
                                : 'text-white')}`}
                            >
                              {description}
                            </span>
                          </td>
                          <td className="text-xs font-light whitespace-nowrap">
                            <span
                              className={`${+(color === 'light'
                                ? 'text-blueGray-600'
                                : 'text-white')}`}
                            >
                              {createdAtFormatted}
                            </span>
                          </td>
                          <td className="flex py-2">
                            <ModalButton
                              buttonContent={<FaEye size={15} color="#fff" />}
                              title="Detalhes da empresa"
                              className="mr-2 p-2 rounded bg-lightBlue-500"
                            >
                              <ModalDetails
                                openForm={() => handleEdit(identifier)}
                                identifier={identifier}
                              />
                            </ModalButton>
                            <button
                              type="button"
                              className="p-2 rounded bg-red-500"
                              onClick={() => {
                                apiDelete({
                                  name,
                                  route: `/adm/company/${identifier}/remove`,
                                });
                              }}
                            >
                              <FiTrash size={15} color="#fff" />
                            </button>
                          </td>
                        </tr>
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Container>
        </CardTableList>
        <TablePaginator
          data={companies}
          emptyMessage="Nenhuma empresa encontrada"
          isLoading={isLoading}
          meta={meta}
          setCurrentPage={setCurrentPage}
        />
      </PageCard>
    </>
  );
};

export default Company;
