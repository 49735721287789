import React from 'react';
import { BsPencilSquare } from 'react-icons/bs';

import useGet from 'services/hooks/useGet';
import { useProfile } from 'services/profile/getProfile';

function ModalDetails({
  closeModal,
  openForm = () => {},
  identifier: storeIdentifier,
}) {
  const { user } = useProfile();
  const { data: store } = useGet({
    route: `/adm/store/${storeIdentifier}/show`,
  });

  const { name, createdAtFormatted, company, city, streetCode, streetComplement, streetDistrict, streetName, streetNumber, latitude, longitude, token, songs } = store || {};
  return (
    <>
      <main>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Nome
              </p>
              <p>{name}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Estado
              </p>
              <p>{city?.state?.initials}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Cidade
              </p>
              <p>{city?.name}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Logradouro
              </p>
              <p>{streetName}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Número
              </p>
              <p>{streetNumber}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Bairro
              </p>
              <p>{streetDistrict}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                CEP
              </p>
              <p>{streetCode}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Complemento
              </p>
              <p>{streetComplement}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Empresa
              </p>
              <p>{company?.name}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Token
              </p>
              <p>{token}</p>
            </div>
          </div>
        </div>
        <hr className="my-2 border-b-1 border-blueGray-300" />
        <div className="flex flex-wrap">
          <div className="w-full px-4">
            <p className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
              Localização
            </p>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Latitude
              </p>
              <p>{latitude}</p>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Longitude
              </p>
              <p>{longitude}</p>
            </div>
          </div>
        </div>
        {songs?.length > 0 && (
          <>          
            <hr className="my-2 border-b-1 border-blueGray-300" />
            <div className="flex flex-wrap">
              <div className="w-full px-4">
                <p className="block uppercase text-blueGray-600 text-sm font-bold mb-2">
                  Musicas
                </p>
                <ul className="h-32 overflow-y-auto">
                  {songs.map(song => (
                    <li>
                      - {song.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
        <hr className="my-2 border-b-1 border-blueGray-300" />
        <div className="flex justify-end w-full mt-2">
          <button
            className="bg-black text-white text-xs p-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            type="button"
            onClick={() => {
              openForm();
              closeModal();
            }}
          >
            <BsPencilSquare size={20} color="#fff" />
          </button>
        </div>
      </main>
    </>
  );
}

export default ModalDetails;
