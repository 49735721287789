import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FaArrowCircleLeft, FaList, FaRegThumbsDown, FaRegThumbsUp, FaSlash, FaWifi } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import api, { Logout } from 'services/api';
import GomusLogo from '../../../assets/img/logo.png';
import * as yup from 'yup';
import { Redirect } from 'react-router';
import { ReactComponent as WifiOffline } from '../../../assets/img/wifi-off.svg';
import { useParams } from 'react-router-dom';

export default function App() {
  const { slug } = useParams();

  const [position, setPosition] = useState({lat: null, lng: null});
  const [errorGps, setErrorGps] = useState('');
  const [stage, setStage] = useState(1);
  const [openMenu, setOpenMenu] = useState(false);
  const [songs, setSongs] = useState([]);
  const [playlist, setPlaylist] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedStore, setSelectedStore] = useState({});
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [isLoadingPlaylist, setIsLoadingPlaylist] = useState(false);
  const [isLoadingSongs, setIsLoadingSongs] = useState(false);
  const [stores, setStores] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [user, setUser] = useState({});

  useEffect(() => {
    if (slug) {
      api.get(`store/list?slug=${slug}`)
      .then(res => {
        localStorage.setItem("selected-store", JSON.stringify({ entity: res.data.entity }));
        setSelectedStore({ entity: res.data.entity });
        setStage(2);
      })
      .finally(() => {
      });
    }
  }, []);

  useEffect(() => {
    const timeout = setInterval(() => {
      loadPlaylist();
      loadSongs();
    }, 1000 * 15);

    return () => clearInterval(timeout);
  }, []);

  useEffect(() => {
    api.get('/profile/show').then(function(res){
      setUser(res.data)
    });

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function(position) {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      }, function(error) {
        setErrorGps('Não conseguimos saber sua localização');
      });
    } else {
      setErrorGps('Não conseguimos saber sua localização');
    }
  }, [])

  useEffect(() => {
    setIsLoadingStores(true);
    if (position.lat && position.lng) {
      api.get(`store/list?latitude=${position.lat}&longitude=${position.lng}`)
      .then(res => {
        setStores(res.data)
      })
      .finally(() => {
        setIsLoadingStores(false);
      });
    }
  }, [position])

  useEffect(() => {
    let selectedStoreCache = null;
    if (localStorage.getItem("selected-store")) {
      selectedStoreCache = JSON.parse(localStorage.getItem("selected-store"));
    }

    if (!selectedStoreCache?.entity) {
      return;
    }

    loadSongs();
  }, [selectedStore]);
  
  const loadSongs = () => {
    let selectedStoreCache = null;
    if (localStorage.getItem("selected-store")) {
      selectedStoreCache = JSON.parse(localStorage.getItem("selected-store"));
    }

    if (!selectedStoreCache?.entity) {
      return;
    }

    setIsLoadingSongs(true);
    api.get(`song/list/${selectedStoreCache?.entity.identifier}`)
    .then(res => {
      setSongs(res.data)
    })
    .finally(() => {
      setIsLoadingSongs(false);
    })
  }

  const loadPlaylist = () => {
    let selectedStoreCache = null;
    if (localStorage.getItem("selected-store")) {
      selectedStoreCache = JSON.parse(localStorage.getItem("selected-store"));
    }

    if (!selectedStoreCache?.entity) {
      return;
    }

    setIsLoadingPlaylist(true);
    api.get(`store/${selectedStoreCache?.entity.identifier}/playlist`)
    .then(res => {
      setPlaylist(res.data)
    })
    .finally(() => {
      setIsLoadingPlaylist(false);
    })
  }

  // se o slug ser hering, pular para o stage 2 já com a listagem de musicas

  return (
    <>
      {(user?.role == 'ROLE_CLIENT' && !user?.acceptedTerms) && (
        <Redirect
            to={'/app/terms'}
        />
      )}
      <img src={GomusLogo} style={{padding: '20px', margin: 'auto', marginBottom: '30px'}} />
      <h1 className="text-center font-bold text-2xl text-black mb-4">
        {errorGps ? errorGps : (!position.lat && 'Precisamos saber em qual unidade você está!')}
        {errorGps ? errorGps : (!position.lat && <><br /><small>Confirme a permissão de acesso à sua localização.</small></>)}
      </h1>
      <div className="text-center">
        {openMenu && (
          <div className="fixed w-full h-full z-20 bg-gray-800">
            <button
              className="w-full pt-5 pb-10 ml-5 my-1 text-white font-bold text-lg"
              onClick={() => {
                setOpenMenu(false)
              }}
            >
              <FaArrowCircleLeft size="40" />
            </button>
            <button
              className="w-full pt-3 pb-1 my-1 text-white font-bold text-2xl"
              onClick={() => {
                setStage(1)
                // setSongs([])
                // setSelectedStore({})
                setOpenMenu(false)
              }}
            >
              Selecione a unidade
            </button>
            <hr className="w-1/2 inline-block" />
            <button
              className="w-full pt-3 pb-1 my-1 text-white font-bold text-2xl"
              onClick={() => {
                setStage(3)
                loadPlaylist();
                setOpenMenu(false)
              }}
            >
              Fila de reprodução
            </button>
            <hr className="w-1/2 inline-block" />
            <button
              className="w-full pt-3 pb-1 my-1 text-white font-bold text-2xl"
              onClick={() => {
                setStage(4)
                setFeedback('');
                setOpenMenu(false)
              }}
            >
              Feedback
            </button>
            <hr className="w-1/2 inline-block" />
            <button
              className="w-full pt-3 pb-1 my-1 text-white font-bold text-2xl"
              onClick={() => {
                Logout();
              }}
            >
              Sair
            </button>
          </div>
        )}
        <div className="w-full p-4 inline-block">
          {((stage == 1 || !selectedStore) && !errorGps && position.lat) && (
            <>
              {stores.length > 0 ? (
                <>
                  <h1 className="text-center font-bold text-2xl text-black mb-4">Selecione a unidade:</h1>
                  <div className="overflow-y-auto h-96">
                    {stores.map(store => (
                      <button
                        className="w-full bg-gray-200 border rounded py-5 my-1 text-black text-xl"
                        onClick={() => {
                          localStorage.setItem("selected-store", JSON.stringify(store));
                          setSelectedStore(store);
                          setStage(2);
                        }}
                      >
                        {store.entity.name} - {parseFloat(store.distance).toFixed(2)} Km
                      </button>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {isLoadingStores ? (<ClipLoader size={20} color="#0842ff" />) : 'Nenhuma loja perto de você'}
                </>
              )}
            </>
          )}
          {(stage === 2 && selectedStore) && (
            <>
              <div className="flex relative mb-4">
                <div className="w-1/6 self-center">
                  <button
                    type="button"
                    className="p-2"
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  >
                    <FaList color="black" size="24" />
                  </button>
                </div>
                <div className="w-4/6 self-center">
                  <h1 className="w-full text-center font-bold text-2xl text-black">{selectedStore.entity.name}</h1>
                </div>
                <div className="w-1/6 self-center">
                  {selectedStore.entity.isOffline ? (
                    <>
                      <WifiOffline width="30" height="auto" title="Palyer offline" />
                    </>
                  ) : (
                      <FaWifi title="Palyer online" size="30" className="text-black" />
                  )}
                </div>
              </div>
              
              <div className="flex relative mx-auto w-full">
                <input
                  className="border-2 border-primary bg-red transition h-12 px-5 pr-16 rounded-md focus:outline-none w-full text-black text-lg"
                  placeholder="Buscar"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </div>
              <div className="overflow-y-auto h-96">
                {isLoadingSongs && (<ClipLoader size={20} color="#0842ff" />)}
                {songs.map(song => {
                  if (search && !song.entity.name.toLowerCase().includes(search.toLowerCase()) && !song.entity.artist.toLowerCase().includes(search.toLowerCase())) {
                    return;
                  }

                  return (
                    <button
                      className={`w-full bg-${song.onPlaylist ? 'green-300' : 'gray-200'} border rounded py-5 my-1 text-black text-lg`}
                      onClick={() => {
                        api.post(`/playlist/${song.onPlaylist ? 'remove' : 'add'}`, {
                          store: selectedStore.entity.identifier,
                          song: song.entity.id,
                        })
                        .then(res => {
                          toast.success(`Música ${song.onPlaylist ? 'removida' : 'adicionada'} da fila de reprodução`)
                        })
                        .finally(() => {
                          loadSongs();
                        })
                      }}
                    >
                      <b>{song.entity.name}</b><br />{song.entity.artist}
                    </button>
                  )
                })}
              </div>
            </>
          )}
          {(stage == 3 && selectedStore) && (
            <>
              <div className="flex relative mb-4">
                <div className="w-1/6 self-center">
                  <button
                    type="button"
                    className="p-2"
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  >
                    <FaList color="black" size="24" />
                  </button>
                </div>
                <div className="w-4/6 self-center">
                  <h1 className="w-full text-center font-bold text-2xl text-black">Fila de reprodução</h1>
                </div>
                <div className="w-1/6 self-center">
                </div>
              </div>
              
              <div className="overflow-y-auto h-96">
                {isLoadingPlaylist && (<ClipLoader size={20} color="#0842ff" />)}
                {playlist?.map(playlistSong => {
                  if (search && !playlistSong.song.name.toLowerCase().includes(search.toLowerCase()) && !playlistSong.song.artist.toLowerCase().includes(search.toLowerCase())) {
                    return;
                  }

                  return (
                    <button
                      className={`flex w-full bg-${playlistSong.isYours ? 'green' : 'gray'}-300 border rounded py-5 my-1 text-black text-lg`}
                      onClick={() => {
                        if (!playlistSong.isYours) {
                          return;
                        }

                        api.post(`/playlist/remove`, {
                          store: selectedStore.entity.identifier,
                          song: playlistSong.song.id,
                        })
                        .then(res => {
                          toast.success(`Música removida da fila de reprodução`)
                        })
                        .finally(() => {
                          loadPlaylist();
                          loadSongs();
                        })
                      }}
                    >
                      <div className="w-2/6 self-center">
                        {playlistSong.position == 1 ? 'Próxima música' : (
                          <>
                            Escolhida por<br />
                            <small>{playlistSong.user}</small>
                          </>
                        )}

                      </div>
                      <div className="w-4/6 self-center">
                        <b>{playlistSong.song.name}</b><br />{playlistSong.song.artist}
                      </div>
                      <div className="w-4/6 self-center">
                        {playlistSong.song.isQueued ?
                          "Status: enfileirada" :
                          "Status: não enfileirada"
                        }
                        <br />
                        {`Posição: ${playlistSong.position}`}
                      </div>
                    </button>
                  )
                })}
              </div>
            </>
          )}
          {(stage == 4 && selectedStore) && (
            <>
              <div className="flex relative mb-4">
                <div className="w-1/6 self-center">
                  <button
                    type="button"
                    className="p-2"
                    onClick={() => {
                      setOpenMenu(true)
                    }}
                  >
                    <FaList color="black" size="24" />
                  </button>
                </div>
                <div className="w-4/6 self-center">
                  <h1 className="w-full text-center font-bold text-2xl text-black">Feedback</h1>
                </div>
                <div className="w-1/6 self-center">
                </div>
              </div>
              
              {!feedback && (
                <div className="mt-5">
                  <p className="text-xl mb-10">Qual sua opnião em relação a programação musical da unidade?</p>
                  <button
                    type="button"
                    className="bg-green-500 mr-2 p-6 rounded-full text-white"
                    onClick={() => {
                      setFeedback('POSITIVE');
                    }}
                  >
                    <FaRegThumbsUp size="40" />
                  </button>
                  <button
                    type="button"
                    className="bg-red-500 ml-2 p-6 rounded-full text-white"
                    onClick={() => {
                      setFeedback('NEGATIVE');
                    }}
                  >
                    <FaRegThumbsDown size="40" />
                  </button>
                </div>
              )}

              {feedback && (
                <div className="mt-5">
                  <p className="text-2xl mb-10">Obrigado pelo seu feedback. Deixe uma sugestão para nossa equipe!</p>
                  <Formik
                    initialValues={{
                      description: '',
                    }}
                    validationSchema={yup.object().shape({
                      description: yup.string().required('Campo obrigatório.')
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      api.post('feedback/send', {
                        feedback: feedback,
                        description: values.description,
                        store: selectedStore.entity.identifier
                      }).then(res => {
                        toast.success('Obrigado pelo seu feedback!')
                        setStage(1)
                      }).finally(() => {
                        setSubmitting(false);
                      })
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form autoComplete="off" onSubmit={handleSubmit}>
                        <div className="flex flex-wrap">
                          <div className="w-full px-4">
                            <div className="relative w-full mb-3">
                              <textarea
                                value={values.description}
                                name="description"
                                placeholder="Escreva aqui"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                rows="7"
                              />
                              <ErrorMessage
                                component="p"
                                className="text-red-500 mb-4"
                                name="description"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center w-full mt-2 mb-20">
                          <button
                            disabled={isSubmitting}
                            className="bg-black text-white text-xl font-bold py-2 px-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                            type="submit"
                          >
                            {isSubmitting ? (
                              <ClipLoader color="#fff" />
                            ) : (
                              <>Enviar</>
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
