import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AuthLayout from './layouts/Auth';
import AdminLayout from './layouts/Admin';
import { useCreateHistory } from '../services/history';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}) {
  useCreateHistory();

  var { signed, user, token} = useSelector((state) => state.auth);

  const pathname = window.location.pathname;

  if (!token || token.length === 0) {
    user = null;
  }

  if ((!user || !user.identifier) && isPrivate) {
    return <Redirect to="/" />;
  }

  if (user && user.identifier) {
    if (pathname === '/') {
      if (user.role == 'ROLE_CLIENT') {
        return (<Redirect to={'/app/store'} />);
      }
      return (<Redirect to={'/admin/dashboard'} />);
    }

    if (pathname.indexOf('/admin') === 0 && user.role === 'ROLE_CLIENT') {
      return (<Redirect to={'/app/storedsa'} />);
    }

    if (pathname.indexOf('/app') === 0 && user.role !== 'ROLE_CLIENT') {
      return (<Redirect to={'/admin/dashboard'} />);
    }
  } else if (pathname !== '/') {
    return <Redirect to="/" />;
  }

  const Layout = signed ? (user.role == 'ROLE_CLIENT' ? AuthLayout : AdminLayout) : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
